import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import QRCodeCard from "../../../../utils/QrCodeCard";
import {
  updateDepositAmount,
  createDeposit,
} from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import CopyToClipboard from "../../../components/copyToClipBoard";
const DepositGateway = () => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { gateway } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const isBank = gateway === "bank_transfer";

  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);
  // console.log("selectedGateway", selectedGateway);
  const validateData = (dataToValidate) => {
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate);
    setErrors(validations);
    return validations;
  };
  const validateBankFormData = (data) => {
    const { amount, utrNo, txnImage } = data;
    let errorVar = {};
    if (!amount) {
      errorVar.amount = "Amount is required";
    }
    if (!utrNo) {
      errorVar.utrNo = "UTR is required";
    }
    if (!txnImage) {
      errorVar.txnImage = "Txn image is required";
    }
    setErrors(errorVar);
    return errorVar;
  };
  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    if (isBank) {
      validateBankFormData(data);
    } else {
      validateData(data);
    }

    setFormData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isBank) {
      if (Object.values(validateData({ ...formData })).length == 0) {
        // console.log("formData", formData);
        if (
          selectedGateway.fields.some(
            (f) => f?.accessor === "gateway" && f?.required === true
          )
        ) {
          formData["gateway"] = selectedGateway?.code;
        }
        const response = await updateDepositAmount({
          data: formData,
          url: selectedGateway?.url,
          isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file"),
        });
        setIsLoading(false);
        handleSubmitResponse(response);
        // console.log("response", response);
      }
      setIsLoading(false);
    } else {
      if (Object.values(validateBankFormData(formData)).length === 0) {
        const response = await createDeposit(formData);
        if (response?.data?.error === false) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, response.data.data.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    }
  };

  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
      } else if (value?.data?.data?.data && "payUrl" in value?.data.data.data) {
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      }
    }
  };

  const renderFields = (field) => {
    switch (field.type) {
      case "file":
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName"
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={(e) => handleChange(e, "file")}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span className="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      default:
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName "
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={handleChange}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span className="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
    }
  };

  const renderBankTransferForm = () => {
    return (
      <Form className="gatewayForm">
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"number"}
            placeholder={"Enter amount"}
            name={"amount"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["amount"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["amount"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"text"}
            placeholder={"Enter UTR no"}
            name={"utrNo"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["utrNo"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["utrNo"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"file"}
            placeholder={"Select file"}
            name={"txnImage"}
            onChange={(e) => handleChange(e, "file")}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["txnImage"]}
          />
          <span className="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["txnImage"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    );
  };
  return (
    <>
      <HeaderAfterLogin />
      <main className="main mt-5">
        {!isBank ? (
          <Container>
            <div className="heading">{selectedGateway?.name}</div>
            {selectedGateway?.type === "manual" && (
              <QRCodeCard text={selectedGateway?.value} />
            )}
            <Form className="gatewayForm">
              {selectedGateway?.fields?.map((f, i) => {
                return renderFields(f);
              })}
              <span className="focus-border">
                <i></i>
              </span>
              <Button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </Form>
          </Container>
        ) : (
          <Container>
            <div className="heading">{"Bank Transfer"}</div>
            {bankDetails && (
              <div style={{ padding: "15px 0 0 5px" }}>
                <p>
                  Account name : {bankDetails?.acc_name}{" "}
                  <CopyToClipboard text={bankDetails?.acc_name} />
                </p>
                <p>
                  Account number : {bankDetails?.acc_no}{" "}
                  <CopyToClipboard text={bankDetails?.acc_no} />
                </p>
                <p>
                  IFSC code : {bankDetails?.ifsc_code}{" "}
                  <CopyToClipboard text={bankDetails?.ifsc_code} />
                </p>
                <p>
                  Bank name : {bankDetails?.bank_name}{" "}
                  <CopyToClipboard text={bankDetails?.bank_name} />
                </p>
                <p style={{ color: "red" }}>Note : Minimum deposit is 300</p>
              </div>
            )}
            {bankDetails && renderBankTransferForm()}
          </Container>
        )}
      </main>
      <Footer />
    </>
  );
};
export default DepositGateway;
