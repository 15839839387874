import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class MegaChallengeTnC extends PureComponent {
  render() {
    return (
      <>
        <HeaderAfterLogin />

        <main className="main">
          <div className="container">
            <h2 className="sectionTitle mt-4">
              Mega Challenge Terms And Condition
            </h2>
            <div className="privay-content">
              <h3 className="mb-4">Terms & Conditions- </h3>
              <p>
                1. This contest is exclusively for the T20 Women's World Cup
                2024.
              </p>
              <p>
                2. Participants must place a bet on every match in the
                tournament, with a minimum bet of ₹1000 per match.
              </p>
              <p>3. Only odds-based betting is permitted.</p>
              <p>
                4. Players using multiple accounts or detected with the same IP
                address will be disqualified from the contest.
              </p>
              <p>
                5. Any fraudulent activity, including collusion with friends or
                family or any suspicious behavior, will result in
                disqualification. Daddy11 reserves the right to terminate such
                accounts immediately.
              </p>
              <p>
                6. Winners will be selected through a lucky draw, with the top
                10 players eligible. The draw will be conducted live on our
                Youtube channel, DaddyScore & News.
              </p>
              <p>
                7. The winners will be announced on the day of the final match.
              </p>
              <p>
                8. Players can track their position on the leaderboard daily
                through our support number's WhatsApp status.
              </p>
            </div>
          </div>
        </main>

        <Footer />
      </>
    );
  }
}

export default MegaChallengeTnC;
