import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { balanceUpdate, logoutUser } from "../redux/auth/actions";
import { socketFunc } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import { BETBY_REDUX } from "../redux/app/types";

function SocketProvider({ children }) {
  const token = useSelector((state) => state.auth?.token);
  const betbyDetails = useSelector((state) => state.app?.betbyDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = socketFunc(token);

    if (token) {
      socket.emit("session_check", (data) => {
        if (data?.status === "failed") {
          dispatch(logoutUser());
          // window.location.href = "/";
        }
      });

      socket.on("session_refresh", () => {
        // console.log();
        socket.emit("session_check", (data) => {
          if (data?.status === "failed") {
            dispatch(logoutUser());
            // window.location.href = "/";
          }
        });
      });

      socket.on("connect_error", async (error) => {
        // console.log("errorname", error.message, error.data);
        // console.log(error, error.name);
        if (error?.data?.type === "SessionError") {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, error?.data?.message);
          dispatch(logoutUser());
          // window.location.href = "/";
          return;
        }
        // revert to classic upgrade
        socket.io.opts.transports = ["polling", "websocket"];
      });

      socket.emit("get:balance", (data) => {
        if (data?.status === "success") {
          dispatch(balanceUpdate(data));
        }
      });

      const isBetByPage = window.location.pathname.indexOf("betby") > -1;

      if (
        !isBetByPage &&
        (!betbyDetails || Date.now() - betbyDetails?.lastUpdated > 840_000)
      ) {
        console.log("referesh betby dets");
        socket.emit("get:betby_details", (res) => {
          // console.log(res);
          if (res.data && res?.data?.data) {
            dispatch({
              type: BETBY_REDUX,
              payload: {
                brand_id: res?.data?.data?.brandId,
                token: res?.data?.data?.token,
              },
            });

            return;
          }

          if (res.error) {
            Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.error?.message);
          }
        });
      }

      socket.on("update_balance", (data) => {
        if (!isNaN(parseFloat(data.balance))) {
          dispatch(balanceUpdate(data));
        } else {
          socket.emit("get:balance", (data) => {
            if (data?.status === "success") {
              dispatch(balanceUpdate(data));
            }
          });
        }
      });
    }
    // return () => {};

    return () => {
      socket.off("session_refresh");
      socket.off("update_balance");
    };
  }, [token, dispatch]);

  return <>{children}</>;
}

export default SocketProvider;
