import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

export class BeforeTerms extends PureComponent {
  render() {
    return (
      <>
        <Header />

        <main className="main">
          <div className="container">
            <h2 className="sectionTitle mt-4"> Terms & Conditions</h2>
            <div className="privay-content">
              <h4>Deposit Requirement:</h4>
              <p>
                To participate in this challenge, you must deposit{" "}
                <b> ₹500, 1000 & 5000 </b>
              </p>

              <ul>
                <li>
                  <h5>Bonus Offer:</h5> Upon making the deposit, you will
                  receive ₹10,000, 25k & 1lac as part of the challenge offer.
                </li>
                <li>
                  <h5>Challenge Duration:</h5> The challenge will last for{" "}
                  <b>30 days</b> from the date of deposit.
                </li>
                <li>
                  {" "}
                  <h5>Target Goal:</h5>
                  <ul>
                    <li>
                      1. You are required to reach a target of <b>₹10Lac/10k</b>{" "}
                      in winnings by the end of the 30-day period.
                    </li>
                    <li>
                      2. You are required to reach a target of <b>₹20Lac/25k</b>{" "}
                      in winnings by the end of the 30-day period.
                    </li>
                    <li>
                      3. You are required to reach a target of{" "}
                      <b>₹50Lac/1lac</b> in winnings by the end of the 30-day
                      period.
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Reward Upon Completion:</h5> If you meet the target, you
                  will receive <b>10% of the target amount</b> as a reward
                  (i.e., ₹1,00,000).
                  <ul>
                    <li>
                      <h5>Available Games:</h5>You can participate in any of the
                      following games to meet your target:
                      <ul>
                        <li>1. All Sports Like Cricket Soccer Tennis ETC</li>
                        <li>2. 2. All Casino Games</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <h5>Eligibility:</h5>
              <p>
                Only individuals who have deposited ₹500 are eligible to
                participate in this challenge.
              </p>

              <h5>Terms of Use:</h5>
              <p>
                By participating, you agree to abide by the rules and guidelines
                set by the platform. Any fraudulent activity or violation of
                terms may lead to disqualification from the challenge.
              </p>

              <h5>Modifications: </h5>
              <p>
                The platform reserves the right to modify or cancel the
                challenge at any time without prior notice
              </p>

              <ul>
                <li>
                  <h5>General Terms:</h5>
                  <p>
                    • All winnings are subject to the platform's withdrawal
                    policies.
                  </p>
                  <p>
                    • The platform may request identification or additional
                    information as part of the verification process.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </main>

        <BeforeLoginFooter />
      </>
    );
  }
}

export default BeforeTerms;
