import React, { useState, useEffect } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { casinoTabs } from "../../lib/data/casinoTabs.data";
import { casinoSection } from "../../lib/data/casinoSection.data";
const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);

  const totalGames = {
    ...casinoSection?.popularGames,
    ...casinoSection?.AndarBahar,
    ...casinoSection?.roulettetab,
    ...casinoSection?.dragontiger,
    ...casinoSection?.baccarattab,
    ...casinoSection?.tvShows,
    ...casinoSection?.blackjacktab,
    ...casinoSection?.pokertab,
    ...casinoSection?.spribe,
    ...casinoSection?.supernowa,
    ...casinoSection?.vivo,
  };

  useEffect(() => {
    return () => {
      setFilteredData(null);
    };
  }, []);

  const handleClose = () => {
    setFilteredData(null);
    setShow(false);
  };
  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1;
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("filteredData", filteredData);
  return (
    <>
      <div className="search-box">
        <div className="searchGames" onClick={handleShow}>
          <BiSearch />
          <p>Search games...</p>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="search-modal">
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Search Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search your favourite games here..."
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>

          <ul className="game-list">
            {filteredData &&
              filteredData.map((item) => (
                <li>
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
