import React from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

const Registration = () => {
  return (
    <>
      <Header />

      <main className="main">
        <div className="container">
          <h2 className="sectionTitle mt-4"> Registration </h2>
          <div className="privay-content">
            <p>1. Click on the “Register” tab.</p>
            <p>2. Enter your contact number followed by OTP. </p>
            <p>3. Accurately fill in the following details:</p>

            <ul>
              <li>➔ Enter Full Name</li>
              <li>➔ Select Username</li>
              <li>➔ Select & Confirm Password</li>
              <li>➔ Enter Agent/referral code</li>
            </ul>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default Registration;
