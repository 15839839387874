import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { handleRightSideBar } from "../redux/app/actions";
import { Col, Container, Row } from "react-bootstrap";

// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Instagram from "../assets/images/Instagram.svg";
import Youtube from "../assets/images/youtube.png";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified-icon.svg";
import Gambling from "../assets/images/providers/gamblinecommission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import MenuIcon from "../assets/images/icons/menuicon.png";
import SportsIcon1 from "../assets/images/games-icon/sportsbook.png";
import AppDownload from "../assets/images/icons/download.png";
import InplayIcon from "../assets/images/games-icon/In-play.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon1.png";
import HowToPlayIcon from "../assets/images/games-icon/roulette.png";
import FootLogo from "../assets/images/icons/gaming.png";
import LicenseImg from "../assets/images/icons/license.png";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";
import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/netent.png";
import ProviderLogo3 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo4 from "../assets/images/providers/redtiger.png";
import ProviderLogo5 from "../assets/images/providers/spribe.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const { showRightSideBar } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const isApk = localStorage.getItem("isApk") === "true";

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "gatewaylist",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <Container>
        <div className="providers_sec d-none">
          <Row>
            <Col md={6} xs={12}>
              <div className="provider-mob-border">
                <div className="heading mb-4">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>
                      Play Your favorite Casino Games with{" "}
                      <span>Top Providers</span>
                    </p>
                  </div>
                </div>

                <div className="providers_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </a>
                  <a href="/casino/pragmatic" className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </a>
                  <a href="/casino/onetouch" className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="">
                <div className="heading payment-heading mb-4 ">
                  <div className="text">
                    <h5>Payment Gateway</h5>
                    <p>
                      Accept Process &amp; disburse{" "}
                      <span>Digital Payments </span>
                      for your bussiness
                    </p>
                  </div>
                </div>

                <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={PaymentLogo1} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/pragmatic"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={PaymentLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo4} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo5} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo6} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo7} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-none">
          <div className="providers_img">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="/contactuss">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplays">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrules">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us
            </a>
            {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
          </div>

          <div className="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
            <img
              src={Betfair}
              loading="lazy"
              className="mb-3  mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="#">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                className="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {" "}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  {/* <span className="notification-count">2</span> */}
                </a>
              </li>

              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-section">
          <div className="footer-right-part">
            <div className="providers_img">
              <a href="#">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="#">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="#">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="#">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>

            <div className="social-icons">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {" "}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>

                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href="https://youtube.com/@DADDY11OFFICIAL?si=otVGhWxn_73QLpoc"
                    target="_blank"
                  >
                    {" "}
                    <img src={Youtube} alt="Whatsapp Icon" />
                    {/* <span className="notification-count">2</span> */}
                  </a>
                </li>
              </ul>
            </div>
            <ul className="LicenseImg">
              <li>
                {" "}
                <a href="./licensedaddy11.pdf" target="_blank">
                  <img src={LicenseImg} alt="license img" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <div>
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/disputeresolution">Dispute Resolution</a>
                  </li>
                  {/* <li>
                    <a href="/payout">Payout</a>
                  </li> */}
                  {/* <li>
                    <a href="/rulesandregulations">Rules and Regulations</a>
                  </li> */}
                </ul>
              </div>
              <div>
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/responsiblegame">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/self">Self Exclusion</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu-web">
              <div>
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/contactus">Contact US</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/privacypolicy">Privacy</a>
                  </li>
                  <li>
                    <a href="/terms">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <p>
            <img src={FootLogo} alt="footer logo" />
            These Challenge Terms and Conditions are effective from Sept-24 and
            govern rights and obligations in connection with the use of services
            (the “Services”) provided by DADDY11 Labs Assessment Limited,
            company registration number 15957271 with its (“DADDY11”, “we”,
            “our” or the “Company”), under these Terms, offered through the
            https://propgamer.daddy11.com. You are under no obligation to use
            the Services if you do not agree or understand any portion of these
            Terms, nor should you use the Services unless you understand and
            agree to these Terms. Your use of the Services constitutes your
            agreement to comply with these Terms.
          </p>
        </div>
        <div className="copy-right">
          <p>
            Copyright © 2023 <span>Daddy11</span> | All rights reserved.
          </p>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex m-0 p-0 list-unstyled">
            <li>
              <a href="/sports/Inplay">
                <img src={InplayIcon} alt="inplay" />
                <span>Inplay</span>
              </a>
            </li>
            <li>
              <a href="/sports/Cricket">
                <img src={CricketIcon} alt="cricket" />
                <span>Cricket</span>
              </a>
            </li>
            <li>
              <a
                className="homeactive"
                onClick={() => {
                  dispatch(handleRightSideBar(!showRightSideBar));
                }}
              >
                <img src={MenuIcon} alt="home" />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/casino/evolution">
                <img src={HowToPlayIcon} alt="challenges" />
                <span>Evolution</span>
              </a>
            </li>
            {/* <li>
              <a href="/betby">
                <img src={SportsIcon1} alt="sportsbook" />
                <span>Sportsbook</span>
              </a>
            </li> */}
            <li>
              <a href="https://dl.dropbox.com/scl/fi/b19o6sq27ekbuv6re0w36/Daddy11.apk?rlkey=nq2c2tqzchu83p32zm1xxetus&dl=0">
                <img src={AppDownload} alt="apk" />
                <span>App</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
