import React from "react";
import { useSelector } from "react-redux";

import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

import Challenge1 from "../../../../assets/images/banner/challenges/challenge1.jpg";
import Challenge2 from "../../../../assets/images/banner/challenges/challenge2.jpg";
import Challenge3 from "../../../../assets/images/banner/challenges/challenge3.jpg";
import Challenge4 from "../../../../assets/images/banner/challenges/challenge4.jpg";
import Challenge5 from "../../../../assets/images/banner/challenges/challenge5.jpg";
import Challenge6 from "../../../../assets/images/banner/challenges/challenge6.jpg";

import challengBanner from "../../../../assets/images/banner/challenges/banner.jpg";

const Challenges = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="container">
          <div className="challenges my-4">
            <ul>
              <li>
                <a href="/mobile-number?agent_code=DADBU2RJ">
                  <img src={Challenge1} alt="challenge 1" />
                </a>
              </li>
              <li>
                <a href="/mobile-number?agent_code=DADBBBDW">
                  <img src={Challenge2} alt="challenge 2" />
                </a>
              </li>
              <li>
                <a href="/mobile-number?agent_code=DADBRQ3N">
                  <img src={Challenge3} alt="challenge 3" />
                </a>
              </li>
              <li>
                <a href="/mobile-number?agent_code=DADB3AWG">
                  <img src={Challenge4} alt="challenge 4" />
                </a>
              </li>
              <li>
                <a href="/mobile-number?agent_code=DADB2ZWU">
                  <img src={Challenge5} alt="challenge 5" />
                </a>
              </li>
              <li>
                <a href="/mobile-number?agent_code=DADBTOD6">
                  <img src={Challenge6} alt="challenge 6" />
                </a>
              </li>
            </ul>
          </div>
          <div className="challengBanner">
            <a href="/mobile-number?agent_code=DADBCUYJ">
              <img src={challengBanner} alt="challengBanner" />
            </a>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default Challenges;
