import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, Button, Container } from "react-bootstrap";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import LeftBarSports from "../../../components/LeftBarSports";

import BackIcon from "../../../../assets/svg/BackIcon";

import DepositImgPromotion from "../../../../assets/images/banner/deposit.jpg";
import FirstDepositImgPromotion from "../../../../assets/images/banner/secondDeposit.jpg";
import SecondDepositImgPromotion from "../../../../assets/images/banner/thirsdeposit.jpg";
import ReferralBonusImgPromotion from "../../../../assets/images/banner/referral.jpg";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";

const Promotion = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      {/* <HeaderAfterLogin /> */}
      <main className="main ">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        {isAuth ? <HeaderAfterLogin /> : <Header />}
        <div className="container mt-5">
          <Container>
            <div className="heading">
              <h4>Promotion</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>

            <div className="promotiion-content">
              <Accordion>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Criteria</Accordion.Header>
                  <Accordion.Body>
                    {/* <div className="promotionimg">
                      <img src={DepositImgPromotion} />
                    </div> */}

                    <p>
                      <h5>Criteria For Turnover In Casino Games </h5>
                      <ul>
                        <li>
                          <span style={{ color: "#cbd5e1" }}>
                            {" "}
                            Dragon Tiger-{" "}
                          </span>{" "}
                          Only bets played on Dragon will be counted in the
                          turnover
                        </li>
                        <li>
                          <span style={{ color: "#cbd5e1" }}>
                            {" "}
                            Andar Bahar -{" "}
                          </span>
                          Only bets played on Andar will be counted in the
                          turnover
                        </li>
                        <li>
                          <span style={{ color: "#cbd5e1" }}> Lucky7-</span>{" "}
                          Only bets played on 7 down will be counted in the
                          turnover
                        </li>
                        <li>
                          <span style={{ color: "#cbd5e1" }}> Baccarat- </span>
                          Only bets played on Banker will be counted in the
                          turnover
                        </li>
                        <li>
                          <span style={{ color: "#cbd5e1" }}>
                            {" "}
                            Cricket War-
                          </span>{" "}
                          Only bets played on Baller will be counted in the
                          turnover
                        </li>
                        <li>
                          {" "}
                          <span style={{ color: "#cbd5e1" }}>
                            {" "}
                            Sic Bo-
                          </span>{" "}
                          Only bets played on Odd, Even, Big, and Small won’t be
                          counted rest will be counted
                        </li>
                        <li>
                          <span style={{ color: "#cbd5e1" }}>
                            {" "}
                            Speed Roulette-{" "}
                          </span>
                          Only bets played on Black, Red, Odd, Even, 1-18, 19-36
                          won’t be counted rest will be counted
                        </li>
                      </ul>

                      <h5>Criteria For Turnover In Sports </h5>
                      <ul>
                        <li>
                          The user can play all the sports for the turnover but
                          the odds should be above 1.67 only after that user’s
                          betting amount is calculated in turnover.
                        </li>
                      </ul>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Deposit Bonus</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={DepositImgPromotion} />
                    </div>
                    <h3>First Deposit Bonus :</h3>
                    <p>
                      Get up to 300% bonus on your first deposit as a Daddy11
                      member. From Deposit of RS 300-5000, Bonus will be 300%
                      From Deposit of RS 5001- 10000, Bonus will be 200% From
                      Deposit of RS 10001- 500000, Bonus will be 100%. NOTE:- A
                      30x turnover of the bonus amount must be completed to
                      activate the bonus.NOTE Maximum Bonus you can achieve is
                      50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    SECOND TIME DEPOSIT BONUS :
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={FirstDepositImgPromotion} />
                    </div>
                    <p>
                      Second time lucky, only on Daddy11! Now get a 50% bonus on
                      your second deposit as a Daddy11 member. Note: Eg: If the
                      user deposits Rs. 1000, he will receive a bonus worth
                      Rs.500. The bonus will be split into (10 or 100) equal
                      parts and can be activated as he completes the turnover.
                      The required turnover for the second deposit bonus is 15x
                      which will also be split accordingly. NOTE : Maximum bonus
                      you can achieve is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Third Deposit Bonus :</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={SecondDepositImgPromotion} />
                    </div>
                    <p>
                      Third time lucky, only on Daddy11! Now get a 25% bonus on
                      your third deposit as a Daddy11 member. Note: Eg: If the
                      user deposits Rs. 1000, he will receive a bonus worth
                      Rs.250. The bonus will be split into (10 or 100) equal
                      parts and can be activated as he completes the turnover.
                      The required turnover for the third deposit bonus is 10x
                      which will also be split accordingly. NOTE: Maximum bonus
                      you can achieve is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Referral Bonus :</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={ReferralBonusImgPromotion} />
                    </div>
                    <p>
                      Share your personal referral code with your friend and get
                      a 15% bonus of every single deposit they make thereafter!
                      Note: A.To redeem the bonus amount, the user must complete
                      a turnover of 35x on SPORTSBOOK & CASINO. B. Bonus will be
                      credited after completing the rollover NOTE:-Maximum Bonus
                      you can receive for Referral Bonus is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </div>
        {isAuth ? <Footer /> : <BeforeLoginFooter />}
      </main>
    </div>
  );
};

export default Promotion;
