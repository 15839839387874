import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import MainSlider from "../../../../containers/MainSlider";

import RouletteCat from "../../../../assets/images/categories/Roulette.png";
import AndarBaharCat from "../../../../assets/images/categories/AndarBahar.png";
import BaccaratCat from "../../../../assets/images/categories/Baccarat.png";
import BlackjackCat from "../../../../assets/images/categories/Blackjack.png";
import DragonTigerCat from "../../../../assets/images/categories/DragonTiger.png";
import PokerCat from "../../../../assets/images/categories/Poker.png";
import SpribeCat from "../../../../assets/images/categories/Spribe.png";
import SupernowaCat from "../../../../assets/images/categories/Supernowa.png";
import VivoCat from "../../../../assets/images/categories/Vivo.png";
import TVShowsCat from "../../../../assets/images/categories/TVShow.png";
import AllgamesCat from "../../../../assets/images/categories/AllGame.png";
import kingcat from "../../../../assets/images/categories/crown.png";
import { Dropdown } from "react-bootstrap";
import SpeedRoulette from "../../../../assets/images/roulette/home/speed-roulette1.jpg";
import play from "../../../../assets/images/play-button.png";
import { casinoSection as casinoData } from "../../../../lib/data/casinoSection.data";
import { auraGames } from "../../../../lib/data/auraGames.data";
import GamesSearchModal from "../../../components/GamesSearchModal";
import CasinoBanner from "../../../../assets/images/banner/banner.jpeg";

const LiveCasino = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.app);

  const casinoSection = { ...casinoData, ...auraGames };
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "All Games",
    key: "popularGames",
    imgSrc: AllgamesCat,
  });
  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);
  const [providerName, setproviderName] = useState(null);
  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    { id: 2, name: "Evolution", eventKey: "evolution" },
    { id: 3, name: "Ezugi", eventKey: "ezugi" },
    { id: 4, name: "Vivo", eventKey: "vivo" },
    { id: 6, name: "Supernowa", eventKey: "supernowa" },
  ];
  const tabsArray = [
    {
      id: 1,
      name: "Popular Games",
      key: "popularGames",
      imgSrc: AllgamesCat,
    },
    {
      id: 2,
      name: "Andar Bahar",
      key: "AndarBahar",
      imgSrc: TVShowsCat,
    },
    {
      id: 3,
      name: "Roulette",
      key: "roulettetab",
      imgSrc: RouletteCat,
    },
    {
      id: 4,
      name: "Dragon Tiger",
      key: "dragontiger",
      imgSrc: RouletteCat,
    },
    {
      id: 5,
      name: "Baccarat",
      key: "baccarattab",
      imgSrc: BaccaratCat,
    },
    {
      id: 6,
      name: "Game Shows",
      key: "tvShows",
      imgSrc: TVShowsCat,
    },
    {
      id: 7,
      name: "Blackjack",
      key: "blackjacktab",
      imgSrc: BlackjackCat,
    },
    {
      id: 8,
      name: "Poker",
      key: "pokertab",
      imgSrc: PokerCat,
    },
    {
      id: 9,
      name: "Spribe",
      key: "spribe",
      imgSrc: SpribeCat,
    },
    // {
    //   id: 10,
    //   name: "Supernowa",
    //   key: "supernowa",
    //   imgSrc: SupernowaCat,
    // },
    // {
    //   id: 11,
    //   name: "Vivo",
    //   key: "vivo",
    //   imgSrc: VivoCat,
    // },

    {
      id: 16,
      name: "Kingmaker",
      key: "kingmakerGames",
      imgSrc: kingcat,
    },
    {
      id: 17,
      name: "Slots",
      key: "slotsGames",
      imgSrc: kingcat,
    },
  ];

  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    setCustomActiveTab({
      id: 1,
      name: "All Games",
      key: "allGames",
      imgSrc: RouletteCat,
    });
    filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "evolution" ||
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo"
      );
    }

    setAllGamesData(filteredValues);
  };

  const divRef = useRef();
  const scrollToElement = () => {
    const { current } = divRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>

      <main className="main">
        <div className="LiveCasinoSection">
          {/* <CasinoGamesSection /> */}
          <div className="casino-games d-lg-block d-none">
            <div className="game-filter">
              <div className="providers">
                <div className="SearchGames">
                  <GamesSearchModal />
                </div>
                <Dropdown
                  onSelect={(e) => {
                    const provider =
                      providersArray.find((p) => p?.eventKey === e) || "all";
                    setSelectedProvider(provider);
                    setproviderName(provider?.name);
                    manageActiveTab(provider);
                  }}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="provider-dropdown"
                    value={selectedProvider}
                  >
                    {selectedProvider?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu value={selectedProvider}>
                    {providersArray.map((p, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={p?.eventKey}>
                          {p?.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <ul className="categories" onClick={scrollToElement}>
                {tabsArray?.map((tab, index) => {
                  return (
                    <React.Fragment key={index}>
                      {((casinoSection?.[tab?.key] &&
                        Object.values(casinoSection?.[tab?.key]).some(
                          (s) =>
                            s?.providerName?.toLowerCase() ===
                            selectedProvider?.eventKey
                        )) ||
                        selectedProvider?.eventKey === "all" ||
                        tab?.key == "GamesAll") && (
                        <li
                          onClick={() => {
                            setCustomActiveTab(null);
                            setproviderName(null);
                            if (tab.key === "allGames") {
                              filterAllGamesData();
                            }
                            setActiveTab(tab);
                          }}
                          // key={index}
                        >
                          <a
                            // href="javascript:void(0)"
                            className={getTabActiveClass(tab) ? "active" : ""}
                          >
                            <img src={tab?.imgSrc} alt="roulette" />
                            <span>{tab?.name}</span>
                          </a>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="BannerSection">
            <Container fluid className="">
              <Row>
                <Col lg={12}>
                  <div className="casino-banner">
                    {/* <MainSlider banners={banners} /> */}
                    <img src={CasinoBanner} alt="casino banner" />
                  </div>
                </Col>
              </Row>

              <div className="casino-games d-lg-none d-block">
                <div className="game-filter">
                  <div className="providers">
                    <div className="SearchGames">
                      <GamesSearchModal />
                    </div>
                    <Dropdown
                      onSelect={(e) => {
                        const provider =
                          providersArray.find((p) => p?.eventKey === e) ||
                          "all";
                        setSelectedProvider(provider);
                        manageActiveTab(provider);
                      }}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="provider-dropdown"
                        value={selectedProvider}
                      >
                        {selectedProvider?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu value={selectedProvider}>
                        {providersArray.map((p, index) => {
                          return (
                            <Dropdown.Item key={index} eventKey={p?.eventKey}>
                              {p?.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ul className="categories">
                    {tabsArray?.map((tab, index) => {
                      return (
                        <React.Fragment key={index}>
                          {((casinoSection?.[tab?.key] &&
                            Object.values(casinoSection?.[tab?.key]).some(
                              (s) =>
                                s?.providerName?.toLowerCase() ===
                                selectedProvider?.eventKey
                            )) ||
                            selectedProvider?.eventKey === "all" ||
                            tab?.key == "GamesAll") && (
                            <li
                              onClick={() => {
                                setCustomActiveTab(null);
                                if (tab.key === "allGames") {
                                  filterAllGamesData();
                                }
                                setActiveTab(tab);
                              }}
                              // key={index}
                            >
                              <a
                                // href="javascript:void(0)"
                                className={
                                  getTabActiveClass(tab) ? "active" : ""
                                }
                              >
                                <img src={tab?.imgSrc} alt="roulette" />
                                <span>{tab?.name}</span>
                              </a>
                            </li>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="all-images" id="show-content" ref={divRef}>
                <h2>{providerName || activeTab?.name}</h2>
                <ul>
                  {casinoSection?.[activeTab?.key] &&
                  activeTab?.key !== "allGames" &&
                  customActiveTab?.key !== "allGames"
                    ? Object.values(casinoSection?.[activeTab?.key]).map(
                        (casino, index) => {
                          return (
                            <React.Fragment key={index}>
                              {(selectedProvider?.eventKey === "all" ||
                                casino?.providerName?.toLowerCase() ===
                                  selectedProvider.eventKey) && (
                                <li key={index}>
                                  <a href={casino?.redirectUrl}>
                                    <img src={casino?.imgUrl} />
                                    <img src={play} className="play_button" />
                                    {/* <p>{casino?.name}</p> */}
                                    <p className="hover_Name">{casino?.name}</p>
                                  </a>
                                  <span className="focus-border">
                                    <i></i>
                                  </span>
                                </li>
                              )}
                            </React.Fragment>
                          );
                        }
                      )
                    : allGamesData &&
                      allGamesData.map((casino, index) => {
                        return (
                          <li key={index}>
                            <a href={casino?.redirectUrl}>
                              <img src={casino?.imgUrl} />
                              <img src={play} className="play_button" />
                              {/* <p>{casino?.name}</p> */}
                              <p className="hover_Name">{casino?.name}</p>
                            </a>
                            <span className="focus-border">
                              <i></i>
                            </span>
                          </li>
                        );
                      })}
                </ul>
              </div>
            </Container>
          </div>
        </div>
      </main>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default LiveCasino;
