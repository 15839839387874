export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  SPORTS_CATEGORY: {
    CRICKET: "cricket",
    TENNIS: "tennis",
    SOCCER: "soccer",
    INPLAY: "inplay",
  },
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  ENVIRONMENTS: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
});

export const REFERRAL_CODES = [
  { id: 0, referralCode: "DADBU2RJ", referralText: "CH1 500" },
  { id: 1, referralCode: "DADBBBDW", referralText: "CH2 5K" },
  { id: 2, referralCode: "DADBRQ3N", referralText: "CH3 10K" },
  { id: 3, referralCode: "DADB3AWG", referralText: "CH4 25K" },
  { id: 4, referralCode: "DADB2ZWU", referralText: "CH5 50K" },
  { id: 5, referralCode: "DADBTOD6", referralText: "CH6 1Lac" },
  { id: 6, referralCode: "DADBCUYJ", referralText: "Mega Challenge" },
];
