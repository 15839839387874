import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

export class BeforeFairPlay extends PureComponent {
  render() {
    return (
      <>
        <Header />

        <main className="main">
          <div className="container">
            <h2 className="sectionTitle mt-4"> Fair Play</h2>
            <div className="privay-content">
              <p>
                Daddy11 treats all Players equally and respectfully. Daddy11
                Provide Fair Odds and Fair winnings. Fair competition, respect,
                friendship, team spirit, equality, sport without doping, respect
                for written and unwritten rules such as integrity, solidarity,
                tolerance, care, excellence and joy, are the building blocks of
                fair play that can be experienced and learnt both on and off the
                field.
              </p>
            </div>
          </div>
        </main>

        <BeforeLoginFooter />
      </>
    );
  }
}

export default BeforeFairPlay;
